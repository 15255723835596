<template>
  <div style="height: 100vh; overflow: hidden;">
    <div class="homeContainer">
      <div>
        <div class="searchTab">
        <van-tabs type="card" @click="onClickWeek" v-model="activeWeekName">
          <van-tab v-for="(date,index) in dataList" :title="date.name" :name="date.time" :key="index"></van-tab>
        </van-tabs>
      </div>
      <div class="mealTab">
        <van-tabs type="card" @click="onClickMeal" v-model="activeMealName" ref="tabs">
          <van-tab v-for="(time,index) in mealTimeList"  :key="index" :title="time.fareName" ></van-tab>
        </van-tabs>
      </div>
      </div>
      <!--店铺商品展示区 -->
      <div style="height: calc(100% - 5.875rem);">
        <div class="sidebar-box">
          <!-- 左侧导航条 -->
          <div style="width: 30%">
            <van-sidebar v-model="activeKey" class="activeKey">
              <van-sidebar-item :title="item.typeName" v-for="(item, index) in list" :key="index" @click="handleMenulist(item.typeId, item.typeName)"/>
            </van-sidebar>
          </div>
          <!-- 加载提示 -->
          <div class="loadingbox" v-show="loading">
            <van-loading size="27px" vertical type="spinner" color="#F99502" class="loading" >菜品加载中...</van-loading>
          </div>
          <!-- 右侧商品 -->
          <div id="gddb" class="gddb" :style="{}" ref="scrollbar">
            <div class="cateName">{{ cateName }}</div>
            <div style="height: calc(100% - 2rem);">
              <div style="height: 100%; overflow: auto">
                <div style=" display: flex; padding-bottom: 15px; margin: 10px 0 10px 0; " v-for="(item, index) in typeListArr" :key="index">
                  <div class="scroll-right">
                    <div style=" display: flex;">
                      <img :src=" item.uploadFileList && item.uploadFileList.length > 0 ? item.uploadFileList[0].fileUrl : '' " alt=""
                        style="width: 56px;margin-left:10px;border-radius: 5px;height: 56px;"/>
                      <div style="margin-left: 16px;">
                        <div class="title" style="font-size: 14px;color: #3D3D3D;"> {{ item.dishName }} </div>
                        <div style="color:#BABABA;font-size: 12px;margin-top: 3px;display: flex;">
                          <div class="popArea" >
                            <van-popover className="popArea"  v-model="showPopover[index]" trigger="click" :actions="item.showDetail">
                              <template #reference>
                                <div class="hideLongArea">{{ item.showDetail&&item.showDetail.length>0?item.showDetail[0].text:'' }}</div>
                              </template>
                            </van-popover>
                          </div>
                          <span style="margin-right:10px;padding-right: 13px;" >库存{{ item.stockNum }}</span>
                        </div>
                        <div style="display: flex;justify-content: space-between;width: 160px;margin-top: 2px;">
                          <div style="color: #FF8826;">￥<span style="font-size: 14px;">{{ item.dishPrice }}</span> </div>
                          <img
                            v-if="item.showCartImg || item.stepNum == 0"
                            src="../../../assets/home/picCart.png"
                            alt=""
                            @click="addIntoCart(item)"
                            style="width: 21px;height: 21px;"
                          />
                          <div class="cart-stepper" v-else>
                            <van-button plain  color="#ffa800" size="mini" icon="minus" round @click="decreaseQuantity(item)" class="quantity-button" > </van-button>
                            <input type="number" v-model="item.stepNum" min="0" class="quantity-input" :max="item.stockNum" readonly/>
                            <van-button size="mini" plain icon="plus" round color="#ffa800" @click="increaseQuantity(item)" class="quantity-button"
                              :disabled="item.stepNum >= item.stockNum" >
                            </van-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 购物车弹出层 -->
      <van-popup v-model="openCart" style="border-radius: 10px 10px 0 0" position="bottom" :style="{ height: '42%' }" >
        <div class="popup-box">
          <div>
            <span style="font-size: 13px;font-weight: 600;color: #3D3D3D;">购物车<span style="font-size: 13px;color: #767676;">(共{{ shoppingList ? shoppingList.length : "0" }}件 菜品)</span></span>
          </div>
          <div @click="empty" class="empty">
            <van-icon name="delete-o" style="font-size: 12px;" />
            <span>清空购物车</span>
          </div>
        </div>
        <div style="padding-bottom: 60px; height: 93px; overflow: auto">
          <div style="  display: flex;  padding-bottom: 15px;  margin: 10px 0 10px 0;" v-for="(item, index) in shoppingList" :key="index">
            <div class="scroll-right">
              <div style=" display: flex;">
                <img :src="item.uploadFileList && item.uploadFileList.length > 0 ? item.uploadFileList[0].fileUrl : ''"
                  alt=""
                  style="width: 56px;margin-left:28px;border-radius: 5px;height: 56px;"
                />
                <div style="margin-left: 16px;">
                  <div class="title" style="font-size: 14px;color: #3D3D3D;">{{ item.dishName }} </div>
                  <div style="display: flex;justify-content: space-between;width: 253px;margin-top: 8px;">
                    <div style="color: #FA5151;"> ￥<span style="font-size: 20px;">{{ item.dishPrice }}</span> </div>
                    <div class="cart-stepper">
                      <van-button size="mini" plain icon="minus" color="#ffa800" round @click="decreaseQuantityCart(item)" class="quantity-button"></van-button>
                      <input type="number" v-model="item.stepNum" min="0" class="quantity-input" :max="item.stockNum"/>
                      <van-button size="mini" icon="plus" round plain @click="increaseQuantityCart(item)" class="quantity-button" color="#ffa800" :disabled="item.stepNum >= item.stockNum"></van-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 底部结算 -->
        <div class="Floating-box">
          <div class="Floating-li">
            <div class="shoping-left">
              <div style="font-size: 18px;font-weight: 500;color: #3D3D3D;margin-right: 8px;">
                <!-- class="shopping-cart" -->
                <!-- <img
                    src="../../../assets/home/floatCart.png"
                    alt=""
                    style="width: 30px;margin-top: 10px;"
                  />
                  <van-badge :content="valNumber" color="#FFA800">
                    <div class="child" />
                  </van-badge> -->
                共计
              </div>
              <span style="margin-right: 3px;color: #FA5151;">¥</span>
              <span style="font-size: 18px;font-weight:bold;color: #FA5151;">{{ salePrice}}</span>
            </div>
            <div>
              <van-button class="shoping-bt" round type="primary" @click="settlement" >下单</van-button >
            </div>
          </div>
        </div>
      </van-popup>
    </div>
    <div class="Floating-box">
      <div class="Floating-liShop">
        <div class="shoping-leftName">
          <div style="margin-right: 26px;">
            <img @click="openCartList" src="../../../assets/home/floatCart.png" alt="" style="width: 32px;margin-top: 10px;" />
            <van-badge :content="valNumber" color="#FFA800">
              <div class="child" />
            </van-badge>
          </div>
          <span style="margin-right: 3px;">¥</span>
          <span style="font-size: 24px;font-weight:bold">{{ salePrice }}</span>
        </div>
        <div>
          <van-button class="shoping-bt" round type="info" @click="settlement" >下单</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NP from "number-precision";
export default {
  name: "index",
  data() {
    return {
      loading: false,
      showPopover: [],
      activeKey: 0,
      dataList:[],
      mealTimeList:[],
      dishType:"",//菜谱类别id
      goodsName: "",
      merId: "",
      openCart: false,
      showCartImg: true,
      // finished: false,
      valNumber: 0,
      salePrice: 0,
      recipeList:[],
      list: [], //左侧商品大类
      cateName:'',
      typeListArr: [], //右侧商品
      loadArr: [],
      activeWeekName: "",
      activeMealName: "0",
      weekList: [],
      hospitalCode:'',
      shoppingList:[],
    };
  },
  watch: {
    goodsName(val) {
      if (val == "") {
        this.getCategory();
      }
    },
    mealTimeList(val) {
      if (val.length>0) {
        this.fareId=val[this.activeMealName].fareId
      }
    },
  },
  mounted() {
    const roomInfo = JSON.parse(localStorage.getItem('roomInfo'));
    this.hospitalCode = (roomInfo && roomInfo.hospitalId) ? roomInfo.hospitalId : '';
    this.getCategory();
    // this.forceUpdate();
  },
  methods: {
    onClickWeek(name, title) {
      // console.log('日期000',name,this.activeMealName);
      this.activeKey=0
      if(this.recipeList.length>0){
        this.recipeList.forEach(item=>{
          if(name==item.time){
           if(item.fareDetailMsgList&&item.fareDetailMsgList.length>0){
              this.mealTimeList=item.fareDetailMsgList
              // this.activeMealName=item.fareDetailMsgList[0].fareId
              this.activeMealName=0
              this.fareId=item.fareDetailMsgList[this.activeMealName].fareId
              this.$refs.tabs.resize();
              if(item.fareDetailMsgList[0].dishTypeMsgList&&item.fareDetailMsgList[0].dishTypeMsgList.length>0){
                this.list=item.fareDetailMsgList[0].dishTypeMsgList
                this.cateName=this.list[this.activeKey].typeName
                this.dishType = this.list[this.activeKey].typeId;
                // console.log('日期111',name,this.fareId);
               }else{
                this.list=[]
                this.cateName=''
                this.dishType=''
               }
           }else{
            this.activeMealName=''
            this.fareId=''
            this.dishType=''
            this.mealTimeList=[]
            this.list=[]
            this.cateName=''
           }
           this.handleMenulist(this.dishType, this.cateName)
          }
        })
      }
      
    },
    onClickMeal(name, title) {
      // console.log('选择餐次00',name);
      this.activeKey=0
      if(this.mealTimeList.length>0){
        this.fareId=this.mealTimeList[name].fareId
        this.mealTimeList.forEach(el=>{
          if(this.fareId==el.fareId){
            this.list=el.dishTypeMsgList
            if(this.list.length>0){
              // console.log('选择餐次11',this.list,this.activeKey,this.fareId);
              this.cateName=this.list[this.activeKey].typeName
              this.dishType = this.list[this.activeKey].typeId;
              // console.log('选择餐次222',this.list,this.cateName,this.dishType);
            } else {
              this.cateName=''
            }
          }
        })
      }else{
        this.activeMealName=''
        this.list=[]
        this.cateName=''
      }
      this.handleMenulist(this.dishType, this.cateName)
    },
    forceUpdate() {
      let salePrice = 0;
      let valNumber = 0;
      const newShoppingList = [];
      let arr = [];
      arr = this.shoppingList;
      arr.forEach((e) => {
        if (e.stepNum > 0) {
          valNumber = NP.plus(e.stepNum, valNumber);
          salePrice = NP.plus(NP.times(e.dishPrice, e.stepNum), salePrice);
          newShoppingList.push(e);
        }
      });
      this.valNumber = valNumber;
      this.salePrice = salePrice;
    },
    addIntoCart(item) {
      if (item.stockNum == 0 || item.stockNum < 1) {
        this.$toast("该商品暂无库存");
        return;
      }
      let arr = [];
      if (this.shoppingList.length > 0) {
        let shoppingShow = false;
        this.shoppingList.forEach(sho=>{
          if(item.fareId!=sho.fareId||item.weakDay!=sho.weakDay){
            shoppingShow = true;
            this.$toast.fail("仅可预约一个餐次的菜品，请清空购物车后再添加");
          }
        })
        if(shoppingShow){
          return
        }
        let filteredTypeList = []
        this.typeListArr.map((i) => {
          if (i.dishId == item.dishId) {
            i.stepNum = 1;
            filteredTypeList.push(i);
            item.showCartImg = false;
          }
        });
        arr = this.shoppingList;
        filteredTypeList.forEach((item) => {
          let matchingItem = arr.find(
            (shoppingItem) => shoppingItem.dishId === item.dishId
          );
          if (matchingItem) {
            matchingItem.stepNum = item.stepNum;
          } else {
            arr.push(item);
          }
        });
      } else {
        this.typeListArr.forEach((i) => {
          if (i.dishId == item.dishId) {
            i.stepNum = 1;
            arr.push(i);
            item.showCartImg = false;
          }
        });
      }
      this.shoppingList=arr
      this.forceUpdate();
    },
    getCategory() {
      this.$http.getAction("/hospital/cookQuery", {hospitalCode: this.hospitalCode,}).then((res) => {
          if (res.code == 200) {
            this.recipeList = res.data;
            var transformedArray = this.transformArray(res.data);
            this.dataList=transformedArray
            this.activeKey = 0;
            if (res.data.length > 0) {
              this.activeWeekName=res.data[0].time
              if(res.data[0].fareDetailMsgList&&res.data[0].fareDetailMsgList.length>0){
                this.mealTimeList=res.data[0].fareDetailMsgList
                this.fareId=res.data[0].fareDetailMsgList[0].fareId
               if(res.data[0].fareDetailMsgList[0].dishTypeMsgList&&res.data[0].fareDetailMsgList[0].dishTypeMsgList.length>0){
                this.list=res.data[0].fareDetailMsgList[0].dishTypeMsgList
                this.cateName=this.list[0].typeName
                this.dishType = this.list[0].typeId;
               }
              }
              this.handleMenulist(this.dishType,this.cateName)
            }
          }
        });
    },
    openCartList() {
      this.openCart = true;
    },
    openCartDetail() {
      if (this.shoppingList.length == 0) {
        this.$toast("当前购物车是空的哦");
      }
      this.openCart = true;
    },
    empty() {
      let arr = [];
      this.shoppingList=[];
      // 遍历数组，为每个对象修改字段数据
      this.typeListArr.forEach((item) => {
        item["stepNum"] = 0;
        item["showCartImg"] = true;
      });
      this.forceUpdate();
    },
    settlement() {
      // this.showDialog = true;
      if (this.shoppingList.length > 0) {
        this.$router.push({ name: 'payDetail',params: { list: this.shoppingList }});
      } else {
        this.$toast("当前购物车是空的哦");
      }
    },
    increaseQuantity(item) {
      this.typeListArr.map((i) => {
        if (i.dishId == item.dishId) {
          i.stepNum++;
        }
      });
      let filteredTypeList = this.typeListArr.filter(
        (item) => item.stepNum > 0
      );
      let arr = [];
      arr = this.shoppingList;
      filteredTypeList.forEach((item) => {
        let matchingItem = arr.find(
          (shoppingItem) => shoppingItem.dishId === item.dishId
        );
        if (matchingItem) {
          matchingItem.stepNum = item.stepNum;
        } else {
          arr.push(item);
        }
      });
      this.forceUpdate();
    },
    decreaseQuantity(item) {
      let cartArr = this.shoppingList;
      cartArr.map((i) => {
        if (i.dishId == item.dishId) {
          i.stepNum--;
          this.typeListArr.map((ti) => {
            if (ti.dishId == item.dishId) {
              ti.stepNum = i.stepNum;
            }
          });
        }
      });
      this.forceUpdate();
    },
    decreaseQuantityCart(item) {
      let cartArr = this.shoppingList.filter(i => !(i.dishId == item.dishId && i.stepNum == 0));
      cartArr = cartArr.map((i) => {
        if (i.dishId === item.dishId) {
          i.stepNum--;
          
          this.typeListArr.forEach((ti) => {
            if (ti.dishId === item.dishId) {
              ti.stepNum = i.stepNum;
            }
          });
        }
        return i;
      });
      let filteredDishes = cartArr.filter(dish => dish.stepNum !== 0);
      this.shoppingList = filteredDishes;
      this.forceUpdate();
    },
    increaseQuantityCart(item) {
      let cartArr = this.shoppingList;
      cartArr.map((i) => {
        if (i.dishId == item.dishId) {
          i.stepNum++;
          this.typeListArr.map((ti) => {
            if (ti.dishId == item.dishId) {
              ti.stepNum = i.stepNum;
            }
          });
        }
      });
      this.forceUpdate();
    },
    handleMenulist(itemId, itemName) {
      this.typeListArr=[]
      this.dishType = itemId;
      this.cateName = itemName;
      this.loading=true
      this.$http.getAction("/hospital/dishQuery", {
        dishType:itemId,
        hospitalCode: this.hospitalCode,
        weakDay: this.activeWeekName,
        fareId:this.fareId,
        })
        .then((res) => {
          if (res.code == 200) {
            if(res.rows.length>0){
              this.loading=false
              this.loadArr = res.rows;
              let newField = "stepNum";
              let newShow = "showCartImg";
              let detail = 'showDetail';
              this.loadArr.forEach((item) => {
                item[newField] = 0;
                item[newShow] = true;
                item[detail]=[]
              });
              this.typeListArr = this.loadArr;
              this.typeListArr.forEach((type) => {
                if(type.subDishList&&type.subDishList.length>0){
                  type.showDetail = [{ text: type.subDishList.map(item => item.dishName).join('+') }]
                }
                if(this.shoppingList.length>0){
                  this.shoppingList.forEach((shop) => {
                  if (type.dishId == shop.dishId && shop.stepNum > 0 && type.fareId == shop.fareId && type.weakDay == shop.weakDay) {
                    type.stepNum = shop.stepNum;
                    type.showCartImg = false;
                  }
                });
                }
              });
              this.forceUpdate();
            }else{
              this.loading=false
              this.typeListArr=[]
              this.$toast('暂无菜品可预订!')
            }
          }else{
            this.loading=false
            this.$toast(res.msg)
          }
        });
    },
    transformArray(arr) {
      let today = new Date();
      let result = [];

      for (let i = 0; i < arr.length; i++) {
        let date = new Date(arr[i].time);
        let timeDiff = Math.ceil((date - today) / (1000 * 60 * 60 * 24));

        if (timeDiff === 0) {
          result.push({ time: arr[i].time, name: '今天' });
        } else if (timeDiff === 1) {
          result.push({ time: arr[i].time, name: '明天' });
        } else if (timeDiff === 2) {
          result.push({ time: arr[i].time, name: '后天' });
        } else {
          result.push({ time: arr[i].time, name: date.getMonth() + 1 + '-' + date.getDate() });
        }
      }
      return result;
    },
  },
};
</script>
<style>
.van-popover__content {
    overflow: auto !important;
    border-radius: 0.5rem;
}
 .van-popover__action {
    font-size: 12px;
    color: #bababa;
}
</style>
<style lang="less" scoped>
.loadingbox {
  width: 44vw;
  height: 20vh;
  position: absolute;
  top: 40vh;
  left: 50vw;
  .loading {
    position: absolute;
    top: 27px;
    left: 27px;
  }
}
.popArea{
  margin-right:8px;
  padding-right: 13px;
}
.homeContainer {
  background-color: #ffffff;
  height: calc(100% - 4.25rem);
}
.searchTab {
  padding-top: 8px;
  background-color: #ffffff;
  /deep/ .van-tabs__nav--card .van-tab {
    color: #3d3d3d;
    border-right: 0.0625rem solid #f99502;
    border: 1px solid #f99502;
    margin-right: 4px;
    border-radius: 5px;
  }
  /deep/ .van-tabs__nav--card .van-tab.van-tab--active {
    color: #fff;
    background-color: #f99502;
  }
  /deep/ .van-tabs__nav--card {
    box-sizing: border-box;
    height: 1.875rem;
    margin: 0 0.5rem;
    border: none;
    // border: 0.0625rem solid #F99502;
    border-radius: 5px;
  }
}
.mealTab {
  margin-top: 14px;
  width: 64%;
  background-color: #ffffff;
  padding-bottom: 12px;
  /deep/ .van-tabs__nav--card .van-tab {
    color: #3d3d3d;
    border-right: 0.0625rem solid #f99502;
    border: 1px solid #f99502;
    margin-right: 4px;
    border-radius: 15px;
  }
  /deep/ .van-tabs__nav--card .van-tab.van-tab--active {
    color: #fff;
    background-color: #f99502;
  }
  /deep/ .van-tabs__nav--card {
    box-sizing: border-box;
    height: 1.875rem;
    margin: 0 0.5rem;
    width: 70px;
    border: none;
    border-radius: 15px;
  }
  /deep/ .van-tab__text--ellipsis{
    width: 60px;
    text-align: center;
  }
}
.sidebar-box {
  display: flex;
  background-color: #fff;
  height: 100%;
}
.gddb {
  width: 70%;
  // overflow-y: auto;
  // height: 94vh;
  // margin-left: 10px;
  .cateName {
    font-size: 14px;
    color: #3d3d3d;
    margin-left: 10px;
    height: 2rem;
  }
}
.van-badge__wrapper {
  position: absolute;
}
.van-badge.van-badge--fixed {
  width: 30px;
  font-size: 18px;
}
.popup-box {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 1.625rem 1rem 1.725rem;
  border-bottom: 1px solid #d8d8d8;
  .empty {
    color: #9f9f9f;
    font-size: 12px;
  }
}
.scroll-right {
  .hideLongArea{
    width:83px;
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis; 
  }
  .cart-stepper {
    display: flex;
    background-color: #ffffff;
    align-items: center;
    .quantity-input {
      width: 18px;
      height: 15px;
      line-height: 15px;
      border: none;
      background-color: #fff;
      color: #3d3d3d;
      text-align: center;
      margin-right: 4px;
    }

    .quantity-button {
      width: 18px;
      height: 18px;
      font-size: 8px;
      background-color: #fff;
      cursor: pointer;
      margin-right: 6px;
    }
  }
}
.Floating-box {
  position: fixed;
  bottom: 0px;
  // background: #323232;
  width: 100%;
  z-index: 999;
  margin: auto;
  // margin: 10px;
  // padding: 20px 0;
  border-radius: 30px;
  left: 50%;
  transform: translateX(-50%);
  color: #3d3d3d;
  .Floating-li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
    margin-right: 21px;
  }
  .Floating-liShop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 13px;
    background-color: #ffffff;
    height: 68px;
  }

  .shoping-bt {
    background: linear-gradient(180deg, #ffa800 0%, #ff8d1a 100%);
    border-radius: 30px;
    border: 1px solid #ffa800;
    padding: 20px;
    width: 124px;
    font-size: 16px;
  }
  .shoping-left {
    display: flex;
    margin-left: 30px;
    align-items: center;
  }
  .shoping-leftName {
    display: flex;
    margin-left: 30px;
    align-items: center;
  }
  .shopping-cart {
    margin-right: 1.55rem;
  }
  /deep/ .van-info {
    background-color: #ff8826;
  }
}
/deep/.van-badge--fixed {
  position: absolute;
  top: 14px !important;
  right: 0px;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  -webkit-transform-origin: 100%;
  transform-origin: 100%;
}
/deep/ .van-sidebar-item--select::before {
  background-color: #ff8826;
  height: 100%;
}
.activeKey {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  overflow: auto;
}
</style>
